var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"color":"dark","size":"xl","closeOnBackdrop":false,"centered":true,"show":_vm.showDoc},on:{"update:show":function($event){_vm.showDoc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"h5 text-uppercase py-2"},[_c('strong',[_vm._v("Anteprima del File")])]),_c('CButton',{staticClass:"close pt-3",on:{"click":function($event){return _vm.$emit('aggiorna_modale', false)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]},proxy:true},{key:"footer",fn:function(){return [_c('cite',[_vm._v(" File: "+_vm._s(_vm.nome_file))])]},proxy:true}])},[[(_vm.ext == 'MP4')?_c('div',{staticStyle:{"margin":"auto","display":"grid"}},[_c('video',{attrs:{"controls":"","width":"100%"}},[_c('source',{attrs:{"src":encodeURI(_vm.file),"type":"video/mp4"}}),_vm._v(" Spiacente il tuo Browser non supporta la riproduzione video. ")]),_c('div',{staticClass:"download-text pt-3 text-center"},[_c('CButton',{staticClass:"ml-2",attrs:{"id":"btn_download_parent","color":"primary","variant":"outline"}},[_c('a',{attrs:{"id":"btn_download","href":encodeURI(_vm.file),"download":_vm.nome_file,"target":"_blank"}},[_c('i',{staticClass:"fas fa-cloud-download-alt"}),_vm._v(" Download")])])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.ext == 'JPG' ||
        _vm.ext == 'JPEG' ||
        _vm.ext == 'BMP' ||
        _vm.ext == 'GIF' ||
        _vm.ext == 'PNG'
      ),expression:"\n        ext == 'JPG' ||\n        ext == 'JPEG' ||\n        ext == 'BMP' ||\n        ext == 'GIF' ||\n        ext == 'PNG'\n      "}],staticClass:"justify-content-center",staticStyle:{"margin":"auto","display":"flex"}},[_c('CImg',{staticClass:"justify-content-center",staticStyle:{"max-height":"40rem"},attrs:{"src":_vm.file}})],1),(
        _vm.ext == 'DOC' ||
        _vm.ext == 'DOCX' ||
        _vm.ext == 'XLS' ||
        _vm.ext == 'XLSX' ||
        _vm.ext == 'PPTX' ||
        _vm.ext == 'PPT'
      )?_c('div',[_c('VueDocPreview',{attrs:{"value":_vm.file,"type":"office"}})],1):_vm._e(),(_vm.ext == 'PDF')?_c('div',[_c('object',{staticStyle:{"height":"40rem"},attrs:{"width":"100%","data":_vm.file,"type":"application/pdf"}})]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }